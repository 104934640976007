.row {
    color: #fff;
    margin-left: 20px;
    margin-right: 0 !important;
}

.row__posters {
    display: flex;
    overflow-y: hidden; /* hides vertical scrol bar*/
    overflow-x: scroll; /* add horizontal scrol bar*/
    padding: 20px;
}

/* Hides the scroll bar*/
.row__posters::-webkit-scrollbar {
    display: none; 
}

.row__poster {
    object-fit: contain;
    width: 100%;
    max-height: 100px;
    /* margin-right: 10px; */
    transition: transform 450ms;
}

.row__poster:hover {
 transform: scale(1.08);
 opacity: 1;   
}

.row__posterLarge {
    min-height: 250px;
}

.row__posterLarge:hover {
    transform: scale(1.09);
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}