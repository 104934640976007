.nav {
    position: fixed;
    /* top: 0; */
    width: 100%;
    padding: 20px;
    height: 80px;
    z-index: 1;

    transition-timing-function: ease-in ;
    transition: all 0.5s;
}

.nav__black {
    background-color: rgb(227, 147, 18);
}

.nav__logo {
    color:#fff;
    width: 180px;
    font-weight: bolder;
    position: fixed;
    object-fit: contain;
    left: 50px;
    padding-top: 7px;
}

.nav__avatar {
    position: fixed;
    top: 14px;
    width: 50px;
    right: 50px;
    object-fit: contain;
}