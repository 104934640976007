.banner {
  color: white;
  object-fit: contain;
  height: 448px;
}

.banner__contents {
  margin-left: 30px;
  padding-top: 140px;
  height: 340px;
}

.banner__title {
  font-size: 3rem;
  font-weight: 800;
  padding-bottom: 0.3rem;
}

.banner__description {
  width: 45rem;
  line-height: 1.3rem;
  padding-top: 1rem;
  font-size: 0.8rem;
  max-width: 360px;
  height: 80px;
}

.banner__button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 700;
  border-radius: 0.2vw;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  padding-top: 0.5rem;
  background-color: rgba(203, 80, 3, 0.5);
  padding-bottom: 0.5rem;
}

.banner__button:hover {
  color: #000;
  background-color: #e6e6e6;
  transition: all 0.2s;
}

.banner--fadeBottom {
  height: 7.4rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(37, 37, 37, 0.61),
    rgb(22, 21, 21)
  );
}

@media screen and (max-width: 778px) {
  .banner {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 389px) {
  .banner__description {
    width: 30rem;
    line-height: 1.3rem;
    padding-top: 1rem;
    font-size: 0.8rem;
    max-width: 90vw;
    height: 80px;
  }
}
